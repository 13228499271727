import { toast } from "react-toastify";
import AppStore from "../store/AppStore"
import ApiURL from "./ApiUrl";
import AppApi from "./AppApi"
import ErrorMessageFormat from "../error/ErrorMessageFormat";
export class DocumentationApi {
  constructor(private api: AppApi, private store: AppStore) { }

  async getDocumentation(queryId: number) {
    try {
      const res = await fetch(`${ApiURL()}documentation?queryId=${queryId}`, {
        method: "GET",
        mode: "cors",
        headers: this.api.auth.createHeaders(),
      })
      if (res.ok) {
        const data = await res.json();
        return data
      } else {
        const errorData = await res.json();
        toast.error(ErrorMessageFormat(errorData), { autoClose: false });
      }
    } catch (err) {
      toast.error(`Request failed: ${err}.`);
    }
  }
}