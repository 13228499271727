import { toast } from "react-toastify";
import ErrorMessageFormat from "../error/ErrorMessageFormat";
import { IQuery } from "../model/QueryModel";
import AppStore from "../store/AppStore"
import ApiURL from "./ApiUrl";
import AppApi from "./AppApi"
export class QueryApi {
  constructor(private api: AppApi, private store: AppStore) { }

  async getQuery() {
    try {
      const res = await fetch(`${ApiURL()}queries`, {
        method: "GET",
        mode: "cors",
        headers: this.api.auth.createHeaders(),
      })
      if (res.ok) {
        const data = await res.json();
        this.store.query.load(data as IQuery[])
      } else {
        const errorData = await res.json();
        toast.error(ErrorMessageFormat(errorData), { autoClose: false });
      }
    } catch (err) {
      toast.error(`Request failed: ${err}.`);
    }
  }
}
