
import { toast } from "react-toastify";
import ErrorMessageFormat from "../error/ErrorMessageFormat";
import { IFilter } from "../model/CustomFilterModel";
import AppStore from "../store/AppStore"
import ApiURL from "./ApiUrl";
import AppApi from "./AppApi"
export class FiltersApi {
  constructor(private api: AppApi, private store: AppStore) { }

  async getAll() {
    try {
      const res = await fetch(`${ApiURL()}filters`, {
        method: "GET",
        mode: "cors",
        headers: this.api.auth.createHeaders(),
      })
      if (res.ok) {
        const data = await res.json();
        this.store.filter.load(data as IFilter[]);
      } else {
        const errorData = await res.json();
        toast.error(ErrorMessageFormat(errorData), { autoClose: false });
      }
    } catch (err) {
      toast.error(`Request failed: ${err}.`);
    }
  }

  async save(item: IFilter) {
    try {
      const res = await fetch(`${ApiURL()}filter`, {
        method: 'POST',
        mode: "cors",
        headers: this.api.auth.createHeaders(),
        body: JSON.stringify({ ...item }),
      });
      if (res.ok) {
        this.getAll()
      } else {
        const errorData = await res.json();
        toast.error(ErrorMessageFormat(errorData), { autoClose: false });
      }
    } catch (err) {
      toast.error(`Request failed: ${err}.`);
    }
  }

  async delete(id: number) {
    try {
      const res = await fetch(`${ApiURL()}filter?id=${id}`, {
        method: 'DELETE',
        headers: this.api.auth.createHeaders(),
        mode: "cors",
      });
      if (res.ok) { }
      else {
        const errorData = await res.json();
        toast.error(ErrorMessageFormat(errorData), { autoClose: false });
      }
    } catch (err) {
      toast.error(`Request failed: ${err}.`);
    }
  }
}