import './components/locale/locale';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.scss";
import { createRoot } from 'react-dom/client';
import { AuthProvider } from "react-oidc-context";
import { LicenseManager } from '@ag-grid-enterprise/core'
import { ModuleRegistry } from "@ag-grid-community/core"
import { MenuModule } from "@ag-grid-enterprise/menu"
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel"
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export"
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping"
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model"
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel"
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";

import App from './App';
import oidcConfig from './components/config/config';
import { StrictMode } from 'react';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    ExcelExportModule,
    MenuModule,
    RowGroupingModule,
    FiltersToolPanelModule,
    MultiFilterModule,
    SetFilterModule,
    RangeSelectionModule,
    ClipboardModule

])

LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`);

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container);
root.render(
    <StrictMode>
        <AuthProvider {...oidcConfig}>
            <App />
        </AuthProvider>
    </StrictMode>,
)


