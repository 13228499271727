import { toast } from "react-toastify";
import ErrorMessageFormat from "../error/ErrorMessageFormat";
import AppStore from "../store/AppStore"
import ApiURL from "./ApiUrl";
import AppApi from "./AppApi"
export class LicenceApi {
  constructor(private api: AppApi, private store: AppStore) { }

  async getAll(queryId: number, refresh: boolean) {
    try {
      const res = await fetch(`${ApiURL()}?queryId=${queryId}&refresh=${refresh}`, {
        method: "GET",
        mode: "cors",
        headers: this.api.auth.createHeaders(),
      })

      if (res.ok) {
        const data = await res.json();
        const { content, totalElements } = data;
        return { content, totalElements };
      } else {
        const errorData = await res.json();
        toast.error(ErrorMessageFormat(errorData), { autoClose: false });
      }
    } catch (err) {
      toast.error(`Request failed: ${err}.`);
    }
  }
}