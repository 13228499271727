const ErrorMessageFormat = (errorData: any) => {

    const data = `<ul className="uk-list">
                  <li><strong>Message:</strong> ${errorData.message}</li>
                  <li><strong>Timestamp:</strong> ${errorData.timestamp}</li>
                 </ul>`;

    return (
        <div dangerouslySetInnerHTML={{ __html: data }} />
    )
}

export default ErrorMessageFormat