import { useState, useCallback, useMemo, useRef } from 'react';
import { GridState, SideBarDef, ColDef, GridReadyEvent, } from "@ag-grid-community/core"
import { AgGridReact } from "@ag-grid-community/react"
import { useAppContext } from '../../components/context/Context';
import ErrorBoundary from '../../components/error/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import showModalFromId from '../../components/modal/ModalShow';
import { MODAL_NAMES } from '../../components/model/Constants';
import SingleSelect, { IOption } from '../../components/select/SingleSelect';
import Modal from '../../components/modal/Modal';
import SaveFilterModal from '../../components/dialog/SaveFilterModal';
import ApplyGridState from './ApplyGridState';
import { getParams } from './utils';
import ExtractColumnsFromData from './ExtractColumnsFromData';
import LOCALE_DE from "../../components/locale/deutsch.json"
import LOCALE_EN from "../../components/locale/english.json"
import GridScrollBar from './GridScrollBar';
import { toast } from 'react-toastify';
// import localData from "../../junk/data/data.json"

const Licensing = observer(() => {

  const { api, store } = useAppContext();
  const gridRef = useRef<AgGridReact>(null);
  let refresh: boolean = false;

  const [rowData, setRowData] = useState<any[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [colDefs, setColDefs] = useState([]);
  const columnDefs = ExtractColumnsFromData(colDefs);

  const queryId = store.query.queryId;
  const { t: msg } = useTranslation(["home"])
  const [initialState, setInitialState] = useState<GridState>({})
  const [active, setActive] = useState<number | null>(null);

  const saveGridState = () => {
    if (gridRef.current) {
      const gridState = gridRef.current.api.getState();
      setInitialState(gridState)
      showModalFromId(MODAL_NAMES.USER.SAVE_FILTER_MODAL);
    };
  }

  const handleApplyState = (value: number | null) => {
    if (gridRef.current) {
      gridRef.current.api.setGridOption("loading", true);

      resetGridState();
      const filter = store.filter.all.find((item) => item.asJson.id === Number(value));
      const columns: GridState = filter ? filter.asJson.columns : {};
      setActive(value);

      setTimeout(() => {
        ApplyGridState(columns, gridRef);
        gridRef.current!.api.setGridOption("loading", false);
      }, 2000);
    }
  };

  const resetGridState = () => {
    if (gridRef.current) {
      try {
        const apiRef = gridRef.current.api;
        setActive(null)
        apiRef.refreshClientSideRowModel();
        apiRef.clearCellSelection();
        apiRef.setSideBarVisible(true)
        apiRef.closeToolPanel()
        apiRef.resetColumnGroupState();
        apiRef.resetColumnState();
        apiRef.setGridOption('pivotMode', false);
        apiRef.setPivotColumns([]);
        apiRef.clearFocusedCell()
        apiRef.setFilterModel(null)
      } catch (error) {
        toast.error(`An error occured!`, { autoClose: 500 });
      }
    }
  }

  const handleDeleteFilter = async (id: number) => {
    await api.filter.delete(id);
    store.filter.remove(id);
  }

  const onExportExcel = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsExcel(getParams())
    }
  }, [])

  const onLoadFreshData = async () => {
    if (gridRef.current) {
      refresh = true;
      gridRef.current.api.setGridOption("loading", true);
      const _data = await api.license.getAll(queryId, refresh)
      if (_data) {
        setRowData(_data.content || []);
        setTotalElements(_data.totalElements);
      }
      gridRef.current.api.setGridOption("loading", false);
    }
  }

  const filterOptions: IOption[] = useMemo(() =>
    store.filter.all.sort((a, b) => a.asJson.name.localeCompare(b.asJson.name)).map((item) => {
      return { label: item.asJson.name || "", value: item.asJson.id }
    }), [store.filter.all])

  const _theme = window.localStorage.getItem("theme") as string;
  const lang = navigator.language
  const localeText = useMemo(() => { return lang === "de" ? LOCALE_DE : LOCALE_EN }, [lang]);
  const paginationPageSizeSelector = useMemo(() => { return [50, 100, 200, 300, 400, 500] }, []);

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        }
      ],
      position: 'right',
      hiddenByDefault: true
    };
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      enableRowGroup: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter"
    };
  }, []);

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    params.api.setGridOption("loading", true);

    // setRowData(localData as any[]);

    const _data = await api.license.getAll(queryId, refresh);
    if (_data) {
      setRowData(_data.content);
      setTotalElements(_data.totalElements);
    }

    const columns = await api.documentation.getDocumentation(queryId)
    setColDefs(columns || []);
    await api.filter.getAll();
    params.api.setSideBarVisible(true);
    GridScrollBar();

    params.api.setGridOption("loading", false);
  }, [api.documentation, api.filter, queryId, api.license, refresh]);

  return (
    <div className="uk-section licenses">
      <div className="licences-card uk-card uk-card-default uk-card-body uk-border-rounded">
        <div className="uk-flex uk-flex-between" data-uk-grid>
          <div className="uk-inline uk-flex">
            <div>
              <button className="uk-button btn-primary uk-border-rounded uk-margin-bottom" onClick={onExportExcel}>
                {msg("exportButton")}
              </button>
            </div>
            <div>
              <button className="uk-margin-left uk-button btn-primary uk-border-rounded"
                onClick={onLoadFreshData} data-uk-tooltip={msg("reloadTooltip")}>
                {msg("reloadButton")}
              </button>
            </div>
          </div>
          <div className="uk-inline uk-flex">
            <div className="uk-flex uk-flex-between" data-uk-grid>
              <div>
                <button className="uk-margin-right uk-button btn-primary select-filter-button" type="button">
                  {msg("selectFilter")}
                </button>
              </div>
              <div data-uk-dropdown="pos: bottom-center" className="filter-dropdown">
                <ul className="uk-nav uk-dropdown-nav">
                  {store.filter.all.sort((a, b) => a.asJson.name.localeCompare(b.asJson.name)).map((item) => (
                    <li className="uk-margin" key={item.asJson.id}>
                      <button
                        data-uk-tooltip={item.asJson.description}
                        className={`uk-button uk-button-small filter-text-button ${active === item.asJson.id ? "btn-primary" : "uk-button-default"}`}
                        type="button"
                        onClick={() => handleApplyState(item.asJson.id)}>
                        <span> {item.asJson.name.substring(0, 35)}...</span>
                      </button>
                      <button
                        className="filter-delete-button"
                        type="button"
                        data-uk-tooltip="Delete"
                        onClick={() => handleDeleteFilter(item.asJson.id)}>
                        <div className='icon' data-uk-icon="icon: close; ratio: .8"></div>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <SingleSelect msg={msg} name="search-name" options={filterOptions} onChange={handleApplyState} />
              </div>
              <div>
                <button onClick={resetGridState} className=" uk-button btn-primary uk-border-rounded">
                  {msg("resetFilter")}
                </button>
              </div>
            </div>
          </div>
          <div className='uk-flex'>
            <div>
              <button onClick={saveGridState} className="uk-button btn-primary uk-border-rounded">
                {msg("saveFilter")}
              </button>
            </div>
          </div>
        </div>
        <ErrorBoundary>
          <div><small><b>{msg("totalData")}: {totalElements || rowData.length}</b></small></div>
          <div className={_theme === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz"} style={{ height: 900 }}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={rowData}
              defaultColDef={defaultColDef}
              blockLoadDebounceMillis={1000}
              onGridReady={onGridReady}
              sideBar={sideBar}
              localeText={localeText}
              rowGroupPanelShow={"always"}
              tooltipShowDelay={500}
              tooltipMouseTrack={true}
              initialState={initialState}
              pagination={true}
              paginationPageSize={100}
              paginationPageSizeSelector={paginationPageSizeSelector}
            />
          </div>
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <Modal modalId={MODAL_NAMES.USER.SAVE_FILTER_MODAL}>
          <SaveFilterModal initialState={initialState} />
        </Modal>
      </ErrorBoundary>
    </div>
  );
});

export default Licensing;
//